import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { Link } from 'gatsby';

const mock = [
  {
    media: 'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/img8.png',
    title: 'Financial security for loved ones',
    subtitle:
      'Life insurance provides a financial safety net for your loved ones in the event of your death. The death benefit can be used to cover expenses such as funeral costs, outstanding debts and other financial obligations, as well as to provide financial support to your dependents.',
  },
  {
    media:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/Delayedcare.png',
    title: 'Estate planning',
    subtitle:
      'Life insurance can be used as part of an estate plan to help ensure that your assets are distributed according to your wishes.',
  },
  {
    media: 'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/img1.jpg',
    title: 'Business protection',
    subtitle:
      'Life insurance can be used to provide financial protection for a business in the event of the death of a key employee or owner.',
  },
  {
    media:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/Reducedaccesstohealthcare.png',
    title: 'Income replacement',
    subtitle:
      'Life insurance can help replace your income in the event of your death, providing financial support for your dependents.',
  },
  {
    media:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/preventivecare2.jpg',
    title: 'Legacy building',
    subtitle:
      'Life insurance can also be used to build a legacy for your beneficiaries by providing a death benefit that can be used to support their future needs.',
  },
];

const News = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Box marginBottom={4}>
        {/* <Typography
          variant={'h4'}
          gutterBottom
          align={'center'}
          sx={{ fontWeight: 700 }}
        >
          Our latest news
        </Typography> */}
        <Typography
          variant={'h6'}
          component={'p'}
          color={'text.secondary'}
          align={'center'}
        >
        </Typography>
      </Box>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={isMd ? 4 : 2} direction="column">
            {mock.map((item, index) => (
              <Grid
                item
                xs={12}
                key={index}
                data-aos="fade-up"
                data-aos-delay={index * 200}
                data-aos-offset={100}
                data-aos-duration={600}
              >
                <Box
                  component={Card}
                  display={'flex'}
                  flexDirection={{ xs: 'column', sm: 'row' }}
                >
                  <CardMedia
                    title={item.title}
                    image={item.media}
                    sx={{
                      height: { xs: 240, sm: 'auto' },
                      width: { xs: 1, sm: 300 },
                    }}
                  />
                  <CardContent>
                    <Box>
                      <Typography
                        variant="h6"
                        gutterBottom
                        color="text.primary"
                      >
                        {item.title}
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary">
                        {item.subtitle}
                      </Typography>
                    </Box>
                  </CardContent>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={isMd ? 4 : 2} direction="column">
            <Grid item xs={12} data-aos="fade-up">
              <Box component={Card} bgcolor={'primary.main'}>
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    color="text.primary"
                    sx={{ color: 'common.white' }}
                  >
                    You like what you’re reading?
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    sx={{ color: 'common.white' }}
                  >
                    Get online insurance advice.
                  </Typography>
                </CardContent>
              </Box>
            </Grid>
            <Grid item xs={12} data-aos="fade-up">
              <Box component={Card}>
                <CardContent>
                  <Typography variant="h6" gutterBottom color="text.primary">
                    Interactive decision support system
                  </Typography>
                  <Link
                    to={'/contact'}
                    style={{ textDecoration: 'none' }} // Remove default link styling
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      endIcon={
                        <Box
                          component={'svg'}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          width={24}
                          height={24}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                          />
                        </Box>
                      }
                    >
                      Contact us
                    </Button>
                  </Link>
                </CardContent>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default News;
